html, body {
  height: 100%;
  margin: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

html[data-joy-color-scheme="dark"] {
  background-color: var(--joy-palette-neutral-800, #23272b);
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}
/*# sourceMappingURL=index.e801bd53.css.map */
